<template>
	<div>
		<div class="good_add good_addshoped">
		    <header class="addheaders">
		        <span class="back" @click="headback">
		            <i class="el-icon-arrow-left" style="font-size:14px;"></i>
		            返回
		            <i class="line">|</i>
		        </span>
		        <span class="titile">{{texttitle}}</span>
		    </header>
		    <nav>
		        <div class="tradeWarp">
		            <el-form label-width="160px" :rules="tradeRules" :model="tradeForm" ref="tradeForm">
		                <el-form-item label="中文名称" prop="name">
		                    <el-input v-model="tradeForm.name"></el-input>
		                </el-form-item>
		                <el-form-item label="英文名称" prop="en_name">
		                    <el-input v-model="tradeForm.en_name"></el-input>
		                    <p class="tradeTips">当存在英文名称时填写</p>
		                </el-form-item>
		                <el-form-item label="品牌编码" prop="brand_code">
		                    <el-input v-model="tradeForm.brand_code" disabled></el-input>
		                    <p class="tradeTips">编号自动生成,无需手动录入</p>
		                </el-form-item>
		                <el-form-item label="排序" prop="sort">
		                    <el-input v-model="tradeForm.sort" type="number"></el-input>
		                </el-form-item>
		                <el-form-item label="品牌图标" prop="pic_url">
		                    <div class="d-flex align-items-center">
		                        <div class="skeletonize" @click="photoVisible()">
									<img v-if="tradeForm.pic_url" :src="tradeForm.pic_url" alt="">
		                            <i class="el-icon-plus" v-else></i>
		                        </div>
		                    </div>
		                </el-form-item>
		                <el-form-item label="是否显示" prop="is_show">
		                    <el-radio-group v-model="tradeForm.is_show">
		                        <el-radio :label="1">显示</el-radio>
		                        <el-radio :label="0">隐藏</el-radio>
		                    </el-radio-group>
		                </el-form-item>
		            </el-form>
		        </div>
		    </nav>
		</div>
		<diy-footer-button v-if="hasPerm(['goods.brand.update'])">
		    <el-button type="primary" @click="preserve('tradeForm')">保存</el-button>
		</diy-footer-button>
		
		<!-- 图片选择器 -->
		<pictureSelect ref="imglist" :multipled="is_multiple"  @change="imgbrand($event)"></pictureSelect>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				textid: this.$route.query.id,
				texttitle: "添加品牌",
				tradeForm:{
				    name:'', //中文名称
				    en_name:'', //英文名称
				    brand_code:'',  //编码
				    sort:100,   //排序
				    pic_url:'',
				    is_show:0,
				},
				is_multiple:false,
				tradeRules:{
				    name:{
				        required: true,
				        message: '请输入中文名称',
				        trigger: 'blur'
				    },
				    brand_code:{},
				},
			}
		},
		mounted: function () {
			console.log(111)
			if(this.textid){
				this.texttitle = "编辑品牌";
				this.loopdetail();
			}
		},
		computed: {
		
		},
		methods: {
			imgbrand(val){
				this.tradeForm.pic_url= val[0].file_url;	
			},
			loopdetail(){
				this.$loading();
				this.$get(this.$apis.loopBrand + this.textid).then(res => {
					this.$loading().close();
					if(res.code == 200){
						this.tradeForm = res.data;
					}else{
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// console.log(err)
					this.common.message(this, err.message);
				});
			},
			// 报存
		    preserve(formName){
				let data = {
					name : this.tradeForm.name,
					en_name : this.tradeForm.en_name,
					pic_url: this.tradeForm.pic_url,
					sort : this.tradeForm.sort,
					is_show : this.tradeForm.is_show,
				};
				if(this.textid){
					this.$refs[formName].validate((valid) => {
						if (valid) {
							this.brandexistapi(this.tradeForm.name,this.tradeForm.en_name,this.textid)
							this.$put(this.$apis.brandedit + this.textid,data).then(res => {
								if(res.code == 200){
									this.$message({
										type: 'success',
										duration: 1500,
										message: '修改成功',
										onClose :()=>{
											this.$router.replace({
												path:"/goods/goods_brand",
											});
										}
									});
								}else{
									this.common.message(this, res.message);
								}
							}).catch(err => {
								// console.log(err)
								this.common.message(this, err.message);
							});
						}else{
							return false;
						}
					})
				}else{
					this.$refs[formName].validate((valid) => {
						if (valid) {
							this.brandexistapi(this.tradeForm.name,this.tradeForm.en_name)
							this.$post(this.$apis.addBrand,data).then(res => {
								if(res.code == 200){
									this.$message({
										type: 'success',
										duration: 1500,
										message: '保存成功',
										onClose :()=>{
											this.$router.replace({
												path:"/goods/goods_brand",
											});
										}
									});
								}else{
									this.common.message(this, res.message);
								}
							}).catch(err => {
								// console.log(err)
								this.common.message(this, err.message);
							});
						}else{
							return false;
						}
					})
				}
			},
			// 判断品牌是否存在
			brandexistapi(name,ename,id){
				let data = {
					name: name,
					en_name: ename,
					id:id
				}
				// console.log(data);
				// return false;
				this.$get(this.$apis.brandexist,data).then(res => {
					if(res.code == 200){
						console.log(res)
					}
				}).catch(err => {
					console.log(err)
					this.common.message(this, err.message);
				});
			},
			photoVisible() {
				this.$refs.imglist.photoVisible = true;
			},
		    headback(){
		        window.history.go(-1)
		    },
		},
	};
</script>

<style scoped>
	.addheaders{margin-bottom: 20px;}
	nav{
	    overflow: auto;
	    padding-bottom: 60px;
	    display: flex;
	    height: 100%;
	    flex-direction: column; 
	    background-color: #fff;
	    border-radius: 4px;
	}
	.tradeWarp{
	    padding: 20px;
	}
	.tradeWarp>.el-form>.el-form-item>.el-form-item__content>.el-input{
	    width: 380px;
	}
	.tradeTips{
	    color: #636c72;
	    font-size: 14px;
	}
	.skeletonize{
	    width: 100px;
	    height: 100px;
	    line-height: 126px;    
	    margin-right: 10px;
	    border: 1px dashed #ededed;
	    text-align: center;
	    color: #dad9d9;
	    float: left;
	    position: relative;
	    cursor: pointer;
	    background: white;
	}
	.skeletonize>i{
	    font-size: 50px;
	}
	.skeletonize img{display: block;width: 100%;height: 100%;object-fit: cover;}
</style>